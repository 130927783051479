import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Input = (props) => {
  const { label, id, name, required, type, large } = props;
  return (
    <Root large={large}>
      <label htmlFor={id} className="white">
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        required={required}
        className="black"
      />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  large: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

const Root = styled.div`
  ${vw('margin-bottom', 40)}
  label {
    display: block;
    ${vw('margin-bottom', 8)}
  }
  input {
    border: none;
    width: 100%;
    background-color: white;
    ${vw('height', 33, 40)}
    ${vw('padding', 8)}
    ${vw('padding-left', 8, 16)}
    @media ${media.desktop} {
      width: ${(props) => (props.large ? '100%' : vwDesktop(423))};
    }
  }
`;

export default Input;
