import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import Header from 'components/Header/Header';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ThankyouPC = () => {
  const $root = useRef();

  useEffect(() => {
    scroll.scrollTo($root.current.offsetTop, { smooth: true });
  }, []);

  return (
    
      <Root ref={$root} name="main">
        <Header />
        <h1 className="thank-you white">Thank you for registering.</h1>
        <StyledLink to='/' className="cta">
          Return to Homescreen
        </StyledLink>
      </Root>
    
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.background};
  padding: ${vwMobile(120)} ${vwMobile(28)};
  text-align: center;
  h1 {
    text-transform: uppercase;
    margin-bottom: ${vwMobile(8)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(120)} ${vwTablet(56)};
    h1 {
      white-space: nowrap;
      margin-bottom: ${vwTablet(8)};
    }
    h1,
    h2 {
      text-align: center;
    }
  }
  @media ${media.desktop} {
    height:100vh;
    h1 {
      margin-bottom: ${vwDesktop(8)};
    }
    padding: ${vwDesktop(120)} ${vwDesktop(392)};
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.black};
  transition: 0.4s ease;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  ${vw('margin-top', 40, 48)}
  ${vw('padding-right', 14, 16)}
  ${vw('padding-left', 14, 16)}
  ${vw('height', 42, 48)}
  @media ${media.desktop} {
    &:hover {
      background-color: white;
    }
  }
`;

export default ThankyouPC;
