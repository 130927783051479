module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  dbProject: 'Solo 4',
  analytics: {
    gtag: '',
    gtmId: 'GTM-WJLLJNZ',
    optimizeId: '',
    hotjarId: '',
  },
  recaptcha: '6LdTd9kbAAAAACBJf_U4go6jv1uuz5ekLwQX5ICL',
  rs: '6LdTd9kbAAAAAEIalG7U947DtQQgdpscs2KntR68',
  seo: {
    title: 'Solo 4',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
