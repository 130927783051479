import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import Page from 'components/Page';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const NotFound = () => {
  useEffect(() => {
    scroll.scrollTo(window.innerHeight, { smooth: false });
  }, []);
  return (
    <Page>
      <Root name="main">
        <h1 className="thank-you white">Sorry, page not found.</h1>
        <h2 className="white light smaller">
          We can’t find what you’re looking for.
        </h2>
        <Link to="/">
          <Button className="cta dark">go to registration page</Button>
        </Link>
      </Root>
    </Page>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  padding: ${vwMobile(80)} ${vwMobile(28)};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-transform: uppercase;
  }
  h2 {
    margin: ${vwMobile(8)} 0 ${vwMobile(40)};
  }
  @media ${media.tablet} {
    align-items: flex-start;
    text-align: left;
    padding: ${vwTablet(120)} ${vwTablet(55)};
    h2 {
      margin: ${vwTablet(6)} 0 ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(120)} ${vwDesktop(392)};
    h2 {
      margin: ${vwDesktop(8)} 0 ${vwDesktop(40)};
    }
  }
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.color.yellow};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${vwMobile(247)};
  height: ${vwMobile(42)};
  @media ${media.tablet} {
    width: ${vwTablet(283)};
    height: ${vwTablet(48)};
  }
  @media ${media.desktop} {
    transition: 0.4s ease;
    cursor: pointer;
    width: ${vwDesktop(283)};
    height: ${vwDesktop(48)};
    &:hover {
      background-color: white;
    }
  }
`;

export default NotFound;
