import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  return (
    <Root>
      <Image />
      <Pattern>
        <Logo
          src={require('src/assets/images/logos/white+yellow-o.svg')}
          alt="Solo 4"
        />
        <h1 className="smaller white">
          Introducing the <span className="yellow">Final Tower </span>
          at SOLO District
        </h1>
      </Pattern>
      <Link to="main" smooth>
        <Scroll
          src={require('src/assets/images/icons/Scroll-down_arrows.svg')}
          alt="Scroll"
        />
      </Link>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
`;

const Image = styled.div`
  width: 100%;
  height: 72vh;
  background-image: url(${require('src/assets/images/rendering/mobile_hero-rendering.jpg')});
  background-size: cover;
  background-position: center;
  @media ${media.tablet} {
    height: 75vh;
    background-image: url(${require('src/assets/images/rendering/tablet_hero-rendering.jpg')});
  }
  @media ${media.desktop} {
    height: 100%;
    background-image: url(${require('src/assets/images/rendering/desktop_hero-rendering.jpg')});
    background-position: center bottom;
  }
`;

const Pattern = styled.div`
  width: 100%;
  height: 28vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${require('src/assets/images/pattern/mobile_Pattern@1x.svg')});
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 0 ${vwMobile(16)};
  h1 {
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  @media ${media.tablet} {
    height: 25vh;
    padding: 0 ${vwTablet(133)};
    background-image: url(${require('src/assets/images/pattern/Tablet_Pattern@1x.svg')});
    h1 {
      margin-top: ${vwTablet(10)};
    }
  }
  @media ${media.desktop} {
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${vwDesktop(108)};
    padding: ${vwDesktop(28)} ${vwDesktop(30)} 0;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url(${require('src/assets/images/pattern/desktop_Pattern@1x.svg')});
    h1 {
      margin-top: 0;
    }
  }
`;

const Logo = styled.img`
  display: block;
  width: ${vwMobile(287)};
  height: auto;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 50%);
  @media ${media.tablet} {
    width: ${vwTablet(546)};
  }
  @media ${media.desktop} {
    transform: translate(0, 50%);
    left: ${vwDesktop(30)};
    width: ${vwDesktop(546)};
  }
`;

const scrollDown = keyframes`
  0% {
    transform: translate(-50%, -10%);
  }
  100% {
    transform: translate(-50%, 10%);
  }
`;

const scrollDownNotTransform = keyframes`
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(10%);
  }
`;

const Scroll = styled.img`
  display: block;
  width: ${vwMobile(20)};
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${vwMobile(20)};
  animation: ${scrollDown} 2s ease-in-out infinite;
  @media ${media.tablet} {
    width: ${vwTablet(34)};
    bottom: ${vwTablet(25)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(34)};
    bottom: ${vwDesktop(145)};
    transform: translateX(0);
    right: ${vwDesktop(30)};
    left: auto;
    z-index: 2;
    cursor: pointer;
    animation: ${scrollDownNotTransform} 2s ease-in-out infinite;
  }
`;

export default Hero;
