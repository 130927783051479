import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Footer from '../components/Footer';
import LogoFile from 'src/assets/images/logo-white.svg';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const PrivacyPolicy = () => {

  return (
    <Root>
      <TopRow>
        <Link to="/">
          <Button>BACK<span className="hide-on-mobile">&nbsp;TO REGISTRATION</span></Button>
        </Link>
        <Logo src={LogoFile} />
      </TopRow>
      <Wrapper>
        <h2>Privacy Policy</h2>
        <h4>A. COMMITMENT</h4>
        <p>Appia Developments (2001) Ltd. (the “Company”) is committed to protecting Personal Information provided by its vendors, customers and other individuals. The Company manages the collection, use and disclosure of Personal Information in accordance with the Personal Information Protection Act (British Columbia). The purpose of this policy is to outline the practices that the Company follows in protecting and managing Personal Information.</p>

        <h4>B. DEFINITIONS</h4>
        <p>
          In this Privacy Policy:<br/><br/>
          “Personal Information” is information about an identifiable individual such as an individual’s name, title, address, telephone number or e-mail address.<br/><br/>
          “Privacy Commissioner” shall mean the Privacy Commissioner of British Columbia.
        </p>

        <h4>C. COLLECTION OF PERSONAL INFORMATION</h4>
        <p>
          The Company only collects Personal Information that is necessary for operating its business, including Personal Information needed to:<br/><br/>
  establish and maintain commercial relationships;<br/>
communicate and make payments to vendors, customers and other individuals;<br/>
manage and develop its business and operations; and<br/>
meet legal and regulatory requirements.<br/><br/>
In general, the Company only collects information directly from the individuals to which it applies. However, the Company may collect information from other people with the consent of the individuals to whom the information applies, or as authorized by law.
        </p>

        <h4>D. CONSENT</h4>
        <p>
          The Company will ask for consent to collect, use or disclose Personal Information, except in circumstances where collection, use or disclosure of Personal Information without consent is authorized or required by law. The Company may imply consent in cases where an individual volunteers information for an obvious purpose.<br/><br/>
          Individuals may withdraw their consent to the use and disclosure of Personal Information at any time, unless the Personal Information is necessary to fulfill the Company’s legal obligations. The withdrawal of an individual’s consent to the use and disclosure of Personal Information may mean that the Company will not be able to continue a commercial relationship or provide certain products and services if it does not have the necessary Personal Information.
        </p>

        <h4>E. USE AND DISCLOSURE OF PERSONAL INFORMATION</h4>
        <p>
          The Company will use and disclose Personal Information only for the purposes for which the information was collected, except as authorized by law. If the Company wishes to use or disclose Personal Information for a new purpose, the Company will ask for consent unless consent is not required by law.<br/><br/>
          Additionally, the Company may disclose Personal Information to other third party service providers for the purposes of providing services or functions on behalf of the Company. Personal Information will not be disclosed to such third party service providers except as described in this Policy, with consent of the individual or as required or permitted by law. The Company will endeavour to ensure that its contracts with third party service providers limit the retention, use and disclosure of Personal Information by the third party service provider solely for the purpose of carrying out the contracted services and provide a level of protection for Personal Information that is comparable to that provided by the Company.<br/><br/>
          As permitted by law, the Company may also use Personal Information to provide individuals with marketing materials, whether by mail, or phone or e-mail, that may contain Company news, updates and related product or service information, including current and future product offerings. Individuals may choose not to receive these materials at any time by contacting the Company at 604-294-2742 or clicking on the “unsubscribe” option in any email communications. Individuals who unsubscribe from marketing and promotional messages may continue to receive service and transactional messages.
        </p>

        <h4>F. WEBSITE</h4>
        <p>
          The Company website may automatically collect certain non-identifiable information regarding website users, such as the computer Internet Protocol (IP) address, the Internet Service Provider IP address, the date and time the website was accessed, the sections of the website visited and materials downloaded from the website. This non-identifiable information is used for website and system administration purposes and to improve the website.<br/><br/>
          The website may use “cookies”, a technology that allows a website to store information on a user’s machine and later retrieve it. Cookies are designed to enhance the convenience and use of the website. Individuals may choose to decline cookies if their browser permits, but doing so may affect the use of the website.
        </p>

        <h4>G. PROTECTING PERSONAL INFORMATION</h4>
        <p>
          The Company protects Personal Information in a manner appropriate to the sensitivity of the information. The Company will make every reasonable effort to prevent loss, misuse, disclosure, copying, modification, disposal or destruction of Personal Information or any unauthorized access to Personal Information. Access to Personal Information is limited to those who require such Personal Information to carry out their responsibilities. The Company will retain Personal Information only for as long as is reasonable to fulfil the purposes for which the information was collected or for legal or business purposes and will employ appropriate measures to render Personal Information non-identifying or destroy Personal Information, including shredding paper records and permanently deleting electronic records, once the information is no longer necessary.
        </p>

        <h4>H. ACCESS TO RECORDS</h4>
        <p>
          Individuals may access their own Personal Information in the Company’s custody or control, subject to certain exceptions. For example, privacy legislation prohibits access to information that may contain Personal Information about another individual or confidential business information. Access may also be refused if the information is subject to solicitor-client privilege. If refusing a request in whole or in part, the Company will provide the reasons for the refusal. In some cases where exceptions to access apply, the Company may withhold that information and provide the remainder of the information.<br/><br/>
          Individuals may make a request for access to their Personal Information by writing to the Privacy Officer. Sufficient information must be provided in the access request to allow the Company to identify the information the individual is seeking. Individuals may also request information about the Company’s use of their Personal Information and any disclosure of that information to persons outside the organization.<br/><br/>
          Additionally, individuals may request a correction if there is an error or omission in their Personal Information.<br/><br/>
          The Company will respond to an individual’s request within the time limits specified by the applicable privacy legislation and will indicate whether the individual is entitled to access the information. If access is refused, the Company will provide reasons for the refusal, the name of the person who can answer questions about the request and advise of the right to have the decision reviewed by the Privacy Commissioner.
        </p>

        <h4>I. COMPLIANCE INQUIRES</h4>
        <p>
          Any inquiry or complaint about this policy or the collection, use and disclosure of Personal Information by the Company should be directed in writing to:<br/><br/>
          Appia Developments (2001) Ltd.<br/>
          1200- 4445 Lougheed Hwy,<br/>
          Burnaby, BC,<br/>
          V5C 0E4<br/>
          Canada<br/><br/>
          If an individual is not satisfied with a response or has a complaint, the individual may contact the Privacy Commissioner.
        </p>


        <h4>J. AMENDMENT</h4>
        <p>
          The Company reserves the right to amend, change, or modify this Policy as necessary from time to time
        </p>
      </Wrapper>
      <Footer />
    </Root>
  );
};

const Root = styled.div`
  color: white;
  background-color: #2c2c2c;

  h2 {
    text-transform: uppercase;
    ${vw('font-size', 16, 32)}
    ${vw('margin-bottom', 38, 40)};
  }

  h4 {
    font-family: Gotham;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    ${vw('margin-top', 38, 40)};
    ${vw('margin-bottom', 16, 24)};
    ${vw('font-size', 12, 14, 20)}
    ${vw('line-height', 16)}
  }

  p {
    ${vw('font-size', 12, 14)}
    ${vw('letter-spacing', 0.5)}
  }
`;

const Wrapper = styled.div`
  ${vw('padding-left', 12, 30, 80)}
  ${vw('padding-top', 48)}
  ${vw('padding-right', 12, 30, 269)}
  ${vw('padding-bottom', 80)}
`;

const TopRow = styled.div`
  ${vw('padding-left', 12, 30, 80)}
  ${vw('padding-top', 16, 48)}
  ${vw('padding-right', 12, 30, 80)}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  ${vw('width', 120, 182, 280)}
  ${vw('height', 31, 47, 73)}
`;

const Button = styled.button`
  font-family: Gotham Book;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('font-size', 14, 16)}
  color: #07272d;
  background-color: ${({ theme }) => theme.color.yellow};
  transition: 0.4s ease;
  cursor: pointer;
  ${vw('width', 84, 254)}
  ${vw('height', 42, 48)}

  @media ${media.desktop} {
    &:hover {
      background-color: white;
    }
  }

  .hide-on-mobile {
    display: none;
    @media ${media.tablet} {
      display: unset;
    }
  }
`;

export default PrivacyPolicy;
