import React from 'react';
import { useLocation } from 'react-router-dom';
import OnUpdate from './OnUpdate';

export default () => {
  const location = useLocation();
  return (
    <OnUpdate
      immediate
      call={() => {
        if (location.pathname === '/') {
          window.scrollTo(0, 0);
        }
      }}
    />
  );
};
