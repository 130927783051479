import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import social from 'src/data/social';

const Footer = () => {
  
  return (
    <Root>
      <HomeLink to="/">
        <Solo
          src={require('src/assets/images/logos/logo_horizontal_black@1x.svg')}
          alt="Solo District"
        />
      </HomeLink>
      <Wrapper>
        <Flex>
          <div>
            <h3 className="dark smaller">follow us</h3>
            <Social>
              {social.map((item, index) => (
                <Icon
                  href={item.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <svg
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="Symbols"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {item.icon}
                    </g>
                  </svg>
                </Icon>
              ))}
            </Social>
          </div>
          <Logos>
            <a
              href="https://www.appiadev.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              <img
                src={require('src/assets/images/logos/Appia_logo@1x.svg')}
                alt="Appia Development"
                className="appia"
              />
            </a>
            <a
              href="https://magnumprojects.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              <img
                src={require('src/assets/images/logos/Magnum_logo@1x.svg')}
                alt="Magnum Projects ltd"
                className="magnum"
              />
            </a>
          </Logos>
        </Flex>
        <p className="disclaimer dark margin">
          © 2021 Appia. All Rights Reserved.{' '}
          <a
            href="https://www.bamdigital.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="dark"
          >
            Website by Bam Digital
          </a>
        </p>
        <p className="disclaimer dark">
          This is not an offering for sale any such offering can only be made
          with a disclosure statement. All renderings and images are artist’s
          representation only. The developer reserves the right to make changes
          and modifications to the information herein without prior notice.
          E&amp;OE
        </p>
        <PrivacyLink
          to="/privacy-policy"
        >
          Privacy Policy
        </PrivacyLink>
      </Wrapper>
    </Root>
  );
};

const Root = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.color.yellow};
  width: 100%;
  padding: ${vwMobile(40)} ${vwMobile(26)};
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-transform: uppercase;
    text-align: center;
  }
  .margin {
    margin-bottom: ${vwMobile(8)};
  }
  .disclaimer {
    text-align: center;
  }
  @media ${media.tablet} {
    padding: ${vwTablet(40)} ${vwTablet(58)};
    .margin {
      margin-bottom: ${vwTablet(8)};
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${vwDesktop(40)} ${vwDesktop(80)};
    .margin {
      margin-bottom: ${vwDesktop(8)};
    }
    .disclaimer {
      text-align: left;
      a {
        position: relative;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: ${({ theme }) => theme.color.darkGrey};
          opacity: 0;
          transition: 0.3s ease;
          position: absolute;
          left: 0;
          bottom: ${vwDesktop(-2)};
        }
        &:hover::after {
          opacity: 1;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    order: 1;
    align-items: flex-start;
  }
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: ${vwDesktop(48)};
  }
`;

const Social = styled.div`
  display: flex;
  margin-top: ${vwMobile(16)};
  a:first-of-type {
    margin-right: ${vwMobile(25)};
  }
  @media ${media.tablet} {
    margin-top: ${vwTablet(16)};
    a:first-of-type {
      margin-right: ${vwTablet(25)};
    }
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(16)};
    a:first-of-type {
      margin-right: ${vwDesktop(25)};
    }
  }
`;

const Logos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: ${vwMobile(48)} 0 ${vwMobile(64)};
  padding: 0 ${vwMobile(20)};
  img {
    display: block;
    height: auto;
    &.appia {
      width: ${vwMobile(70)};
    }
    &.magnum {
      width: ${vwMobile(110)};
    }
  }
  @media ${media.tablet} {
    margin: ${vwTablet(48)} 0 ${vwTablet(64)};
    padding: 0 ${vwTablet(212)};
    img {
      &.appia {
        width: ${vwTablet(70)};
      }
      &.magnum {
        width: ${vwTablet(110)};
      }
    }
  }
  @media ${media.desktop} {
    width: auto;
    margin: 0 0 0 ${vwDesktop(80)};
    padding: 0;
    img {
      &.appia {
        width: ${vwDesktop(70)};
        margin-right: ${vwDesktop(48)};
      }
      &.magnum {
        width: ${vwDesktop(110)};
      }
    }
  }
`;

const HomeLink = styled(Link)`
  display: block;
  width: ${vwMobile(176)};
  margin-bottom: ${vwMobile(64)};
  @media ${media.tablet} {
    width: ${vwTablet(176)};
    margin-bottom: ${vwTablet(64)};
  }
  @media ${media.desktop} {
    order: 2;
    width: ${vwDesktop(176)};
    margin-bottom: 0;
  }
`;

const Solo = styled.img`
  display: block;
  width: ${vwMobile(176)};
  @media ${media.tablet} {
    width: ${vwTablet(176)};
  }
  @media ${media.desktop} {
    order: 2;
    width: ${vwDesktop(176)};
  }
`;

const Icon = styled.a`
  display: block;
  width: ${vwMobile(32)};
  height: ${vwMobile(32)};
  svg {
    width: 100%;
    height: 100%;
  }
  @media ${media.tablet} {
    width: ${vwTablet(32)};
    height: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(32)};
    height: ${vwDesktop(32)};
    cursor: pointer;
    svg rect,
    svg path,
    svg polygon {
      transition: 0.3s ease;
    }
    &:hover svg rect,
    &:hover svg polygon {
      fill: #ffffff;
    }
    &:hover svg path {
      fill: ${({ theme }) => theme.color.black};
    }
  }
`;

const PrivacyLink = styled(Link)`
  font-family: Gotham Book;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.color.black};
  text-decoration: underline;
  margin-top: 8px;

  @media ${media.desktop} {
    position: absolute;
    ${vw('right', 80)};
    ${vw('bottom', 40)};
  }
`;

export default Footer;
