import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ReCaptcha } from 'react-recaptcha-v3';
import Insight from 'src/plugins/Insight';

import Input from './elements/Input';
import Select from './elements/Select';
import Checkbox from './elements/Checkbox';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import createARegistrant from 'src/scripts/send2lass-walk-in';
import { recaptcha, rs } from 'src/config/app.conf';

const Form = () => {
  const [recaptchaResponse, setRecaptchaResponse] = useState({});
  const [guid, setGuid] = useState('');

  const history = useHistory();

  const $recaptcha = useRef();

  useEffect(() => {
    let LassoCRM = window.LassoCRM || {};
    LassoCRM.tracker = new window.LassoAnalytics('LAS-955057-08');
    LassoCRM.tracker.setTrackingDomain('//app.lassocrm.com');

    try {
      LassoCRM.tracker.init(); // initializes the tracker
      LassoCRM.tracker.track();
      LassoCRM.tracker.patchRegistrationForms();
    } catch (error) {}

    setGuid(LassoCRM.tracker.guid);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const postal = document.getElementById('postal').value;
    const brokerage = document.getElementById('brokerage').value;

    const realtor = document.getElementById('realtor');
    const realtorValue = realtor.options[realtor.selectedIndex].value;

    const data = {
      name: firstName + ' ' + lastName,
      email: email,
      phone: phone,
      postal_code: postal,
      brokerage: brokerage,
      realtor: realtorValue,
    };

    let honeypotVal = '';

    document.querySelectorAll('input.honey').forEach((el) => {
      honeypotVal += el.value;
    });

    await updateToken();

    if (honeypotVal !== '' || !recaptchaResponse.success) {
      e.preventDefault();
      return alert(
        `Our systems seem to think you're a bot!  Please email Appia Development and/or Magnum Projects directly to receive more information about Solo 4.`
      );
    }

    Insight(data);

    await createARegistrant();
    history.push('/thankyou');
  };

  const verifyCallback = async (token) => {
    const PROXY = 'https://bam-cors-anywhere.herokuapp.com/';
    const verifyURL = `${PROXY}https://www.google.com/recaptcha/api/siteverify?secret=${rs}&response=${token}`;

    const response = await fetch(verifyURL, {
      method: 'POST',
      accept: 'application/json',
    });
    const responseJson = await response.json();

    setRecaptchaResponse(responseJson);
  };

  const updateToken = async () => {
    // you will get a new token in verifyCallback
    await $recaptcha.current.execute();
  };

  return (
    <Root>
      <form method="post" onSubmit={(e) => handleSubmit(e)}>
        <ReCaptcha
          sitekey={recaptcha}
          ref={$recaptcha}
          action="registration"
          verifyCallback={verifyCallback}
        />
        <input
          type="hidden"
          name="domainAccountId"
          id="domainAccountId"
          value="LAS-955057-08"
        />
        <input type="hidden" name="guid" id="guid" value={guid} />
        <input type="hidden" name="SourceTypeId" value="20482" />
        <input
          className="honey"
          type="text"
          id="name"
          autoComplete="nofill"
          placeholder="Your name here"
        />
        <Row>
          <Input label="First name*" id="firstName" name="firstName" required />
          <Input label="Last name*" id="lastName" name="lastName" required />
        </Row>
        <Row>
          <Input label="Email*" id="email" name="email" required type="email" />
          <Input
            label="Phone number*"
            id="phone"
            name="phone"
            required
            type="tel"
          />
        </Row>
        <Row>
          <Input label="Postal code*" id="postal" name="postal" required />
          <Select
            name="realtor"
            id="realtor"
            label="Are you a realtor?*"
            required
            options={[
              {
                label: 'Yes',
                value: 'Yes',
              },
              {
                label: 'No',
                value: 'No',
              },
            ]}
          />
        </Row>
        <Input
          label="Brokerage (if applicable)"
          id="brokerage"
          name="brokerage"
          large
        />
        <Checkbox />
        <p className="disclaimer white">
          This is not an offering for sale. E. &amp; O.E. Appia Development
          and/or Magnum Projects collects personal information from individuals
          on a voluntary basis through Registration Forms. The information
          provided is used to promote Appia Development and/or Magnum Projects’
          products that meet individual needs or that might be of interest to
          individuals based on information provided and approved by Appia
          Development and/or Magnum Projects. This information is also used to
          assess advertising and marketing initiatives and to obtain demographic
          information, household information and preference information.
        </p>
        <Button type="submit" className="cta dark">
          Submit
        </Button>
      </form>
    </Root>
  );
};

const Root = styled.div`
  .disclaimer {
    text-align: center;
  }
  .honey {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
  @media ${media.tablet} {
    .disclaimer {
      text-align: left;
    }
  }
`;

const Row = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.yellow};
  transition: 0.4s ease;
  cursor: pointer;
  ${vw('margin-top', 40, 48)}
  ${vw('width', 98, 120)}
  ${vw('height', 42, 48)}
  @media ${media.desktop} {
    &:hover {
      background-color: white;
    }
  }
`;

export default Form;
