export default {
  color: {
    white: '#ffffff',
    darkGrey: '#53565a',
    black: '#231f20',
    yellow: '#ece81a',
    background: '#2c2c2c',
  },
  font: {
    family: 'Gotham',
    book: 'Gotham Book',
  },
};
