import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Checkbox = () => {
  return (
    <Root className="white">
      <input type="checkbox" name="consent" id="consent" required />
      <span />
      <label htmlFor="consent" className="white">
        Please click here to confirm that you would like to receive updates on
        SOLO District *
      </label>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  ${vw('margin-bottom', 24, 26)}
  @media ${media.desktop} {
    align-items: center;
  }
  label {
    cursor: pointer;
    ${vw('max-width', 236, 562, 800)}
  }
  input {
    display: block;
    opacity: 0;
    cursor: pointer;
    ${vw('width', 33, 40)}
    ${vw('height', 33, 40)}
    ${vw('margin-right', 16, 22, 24)}
  }
  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    pointer-events: none;
    ${vw('width', 33, 40)}
    ${vw('height', 33, 40)}
    &::after {
      content: '';
      width: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      background-color: ${({ theme }) => theme.color.yellow};
      transition: 0.3s ease;
    }
  }
  input:checked ~ span::after {
    opacity: 1;
  }
`;

export default Checkbox;
