import React from 'react';
import styled from 'styled-components';
import { useScrollDown } from 'bam-storybook';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Header = () => {
  const hasScrolledDown = useScrollDown(50);
  return (
    <Root 
      hasScrolledDown={hasScrolledDown}
      mobile={require('src/assets/images/global/header/mobile_pattern-header.png')}
      tablet={require('src/assets/images/global/header/tablet_pattern-header.png')}
      desktop={require('src/assets/images/global/header/horizontal-pattern.png')}
    >
      <img src={require('src/assets/images/global/header/solo_horizontal_logo.svg')} alt='' />
    </Root>
  )
}

export default Header;

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: ${props => props.hasScrolledDown ? 'translateY(-100%)' : ''};
  background-image: ${props => `url(${props.mobile})`};
  background-size: cover;
  transition: all 0.3s ease;
  z-index: 99;
  ${vw('height', 80, 88)}

  img {
    ${vw('width', 120, 187)}
  }
  @media ${media.tablet} {
    background-image: ${props => `url(${props.tablet})`};
  }
  @media ${media.desktop} {
    background-image: ${props => `url(${props.desktop})`};
  }
`;