import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Select = (props) => {
  const { label, id, name, required, options } = props;
  return (
    <Root>
      <label htmlFor={id} className="white">
        {label}
      </label>
      <div>
        <select id={id} required={required} name={name} className="black">
          <option value="">Select</option>
          {options.map((item, index) => (
            <option value={item.value} name={name} key={index}>
              {item.label}
            </option>
          ))}
        </select>
        <img
          src={require('src/assets/images/icons/drop-down-menu_arrow.svg')}
          alt="Select"
        />
      </div>
    </Root>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
};

const Root = styled.div`
  ${vw('margin-bottom', 40)}
  @media ${media.desktop} {
    width: ${vwDesktop(423)};
  }
  div {
    position: relative;
  }
  label {
    display: block;
    ${vw('margin-bottom', 8)}
  }
  select {
    appearance: none;
    border: none;
    width: 100%;
    background-color: white;
    ${vw('height', 33, 40)}
    ${vw('padding', 8)}
    ${vw('padding-left', 8, 16)}
  }
  img {
    display: block;
    height: auto;
    position: absolute;
    ${vw('width', 14)}
    ${vw('right', 8, 16)}
    ${vw('top', 15, 16)}
  }
`;

export default Select;
