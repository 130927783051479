const LASSO_API_KEY =
  'eyJhbGciOiJSUzI1NiJ9.eyJleHAiOjcyMjY1ODI0MDAsInRva2VuRGF0YSI6IntcbiAgXCJwcm9qZWN0SWRcIiA6IDE2ODgsXG4gIFwiY2xpZW50SWRcIiA6IDM4MCxcbiAgXCJuYW1lXCIgOiBcImxhc3NvUmVnaXN0cmF0aW9uXCJcbn0iLCJpc3MiOiJMYXNzb1Rva2VuIiwiYXVkIjoiTGFzc28iLCJ0b2tlbklkIjo0Mjk4fQ.YrVesnl3VoJfGpaQYrHe0zWcbi0fpMuDJlxGdQeWk2tKkeU-zc03kRDQOZPMOaHuAl10XrQTpI4RB0VUH7o4dVfrctt0mZg9M11V19U_hw_I5a6-srEukfVSYu87NfeIJcuPatqHhq3KyncMx0uz3HuG-G_FVxx8tp8b05xqo9-X4Rwff300-PuaQKL5VQX_Lor2l9b3nExxQGoQjMmqjED3zrfol5QGCq3S4pbB5LG6JqyfX5Ik6qA7xxlOBrE_YITtVrSZkn_HO8Z6REhB35Th3tGFWvPnsF3CRZradsVSdBJqgnGU3WM_iMZ7EikVBwUHP0X_jn17b7dC7x8ZHA';
const PROXY = 'https://bam-cors-anywhere.herokuapp.com/';
const BASE_URL = 'https://api.lassocrm.com/v1';
const REGISTRANTS = '/registrants';

const headers = () => {
  return {
    'Content-Type': 'application/json',
    accept: 'application/json',
    Authorization: `Bearer ${LASSO_API_KEY}`,
  };
};

export default async function createARegistrant(projectId) {
  const HEADERS = headers(projectId);
  const firstName = document.getElementById('firstName').value;
  const lastName = document.getElementById('lastName').value;
  const email = document.getElementById('email').value;
  const phone = document.getElementById('phone').value;
  const postal = document.getElementById('postal').value;
  const brokerage = document.getElementById('brokerage').value;
  const domainAccountId = document.getElementById('domainAccountId').value;
  const guid = document.getElementById('guid').value;

  const realtor = document.getElementById('realtor');
  const realtorValue = realtor.options[realtor.selectedIndex].value;

  const dataBody = {
    websiteTracking: {
      domainAccountId: domainAccountId,
      guid: guid,
    },
    person: {
      firstName: firstName,
      lastName: lastName,
    },
    emails: [
      {
        email: email,
        primary: true,
      },
    ],
    phones: [
      {
        phone: phone,
        primary: true,
      },
    ],
    addresses: [
      {
        zipCode: postal,
        primary: true,
      },
    ],
    thankYouEmailTemplateId: '759113',
    questions: [
      {
        questionId: '18432',
        name: 'Realtor',
        answers: [
          {
            answer: realtorValue,
          },
        ],
      },
      {
        questionId: '114608',
        name: 'If realtor is yes please specify brokerage',
        answers: [
          {
            answer: brokerage,
          },
        ],
      },
      {
        questionId: '114720',
        name: 'Phase 4 interest',
        answers: [
          {
            answer: 'Phase 4',
          },
        ],
      },
      {
        questionId: '30401',
        name: 'Consent',
        answers: [
          {
            answer: 'Yes',
          },
        ],
      },
    ],
    sourceType: {
      sourceType: 'Online Registration',
    },
  };

  const response = await fetch(PROXY + BASE_URL + REGISTRANTS, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(dataBody),
  });

  const responseJson = await response.json();

  return responseJson;
}
