import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .white {
    color: white;
  }

  .grey {
    color: ${theme.color.darkGrey};
  }

  .dark {
    color: ${theme.color.black};
  }

  .yellow {
    color: ${theme.color.yellow};
  }

  h1 {
    font-family: ${theme.font.family};
    font-size: 48px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    ${vw('font-size', 48)}
    &.smaller {
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      ${vw('font-size', 16, 28)}
      ${vw('line-height', 23, 39)}
    }
  }

  h2 {
    font-family: ${theme.font.family};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    ${vw('font-size', 32)}
    &.smaller {
      text-align: center;
      ${vw('font-size', 14, 24)}
      ${vw('line-height', 21, 36)}
    }
    &.light {
      font-family: ${theme.font.book};
      font-weight: normal;
    }
  }

  h3 {
    font-family: ${theme.font.family};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    ${vw('font-size', 21)}
    ${vw('line-height', 16)}
    &.smaller {
      font-family: ${theme.font.book};
      ${vw('font-size', 12, 14)}
      ${vw('line-height', 12, 16)}
    }
  }

  p, label, input, select {
    font-family: ${theme.font.book};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    ${vw('font-size', 12, 14)}
    ${vw('line-height', 16, 22)}
    ${vw('letter-spacing', 0.5)}
  }

  .disclaimer {
    font-family: ${theme.font.book};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    ${vw('font-size', 8, 10)}
    ${vw('line-height', 14, 16)}
    ${vw('letter-spacing', 0.5)}
  }

  .cta {
    font-family: ${theme.font.book};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: uppercase;
    ${vw('font-size', 14, 16)}
  }

  .field-input {
    font-family: ${theme.font.family};
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    ${vw('font-size', 14)}
    ${vw('line-height', 19)}
    ${vw('letter-spacing', 0.5)}
  }

  .thank-you {
    font-family: ${theme.font.family};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    ${vw('font-size', 16, 40)}
    ${vw('line-height', 23, 48)}
  }
`;
