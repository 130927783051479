import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import Footer from 'components/Footer';

const Page = (props) => {
  const { children } = props;
  return (
    <Root {...props}>
      <Hero />
      {children}
      <Footer />
    </Root>
  );
};

const Root = styled.main``;

export default Page;
