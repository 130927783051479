import React from 'react';
import styled from 'styled-components';

import Header from 'components/Header/Header';
import Form from 'components/Form/Form';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Home = () => {
  return (
      <Root name="main">
        <Header />
        <h1 className="smaller white">Thank You For Visiting Our Presentation Centre</h1>
        <br /><br />
        <Form />
      </Root>
    
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  height: 100%;
  padding: ${vwMobile(112)} ${vwMobile(12)} ${vwMobile(80)} ${vwMobile(13)};
  position: relative;
  p{color:#fff;}
  h2 {
    text-transform: uppercase;
    margin-bottom: ${vwMobile(40)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(120)} ${vwTablet(75)} ${vwTablet(80)} ${vwTablet(73)};
    h2 {
      margin-bottom: ${vwTablet(48)};
    }
  }
  @media ${media.desktop} {
    min-height: 100vh;
    padding: ${vwDesktop(200)} ${vwDesktop(256)} ${vwDesktop(80)};
    h2 {
      text-align: left;
    }
  }
`;

export default Home;
